/* RESET */
* {
  margin: 0;
  padding: 0;
}


/* GLOBAL */
:root {
  --default: white;
  --text_default: #5a5a5a;
  --green: #608d55;
  --error: #c96262;
  --blue_dark: #B3CB92;
  /* #7899DC; */
  --light: #F7FBFC;
  --standard_text: #666C78;
  --red_orange:#d14c14;
  --medium:#f1f1f1;
  --light_blue:#F7FBFC;
}

html { 
  overflow-y: scroll;
  scroll-behavior: smooth; 
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Dongle', sans-serif;
  text-align: center;
  background-color: var(--default);
  color: var(--standard_text);
  letter-spacing: 0.1rem;
  font-size: 1rem;
  font-family: 'IBM Plex Sans', sans-serif;
}

ul{
  list-style-type: none;
}


.container {
  padding: 6% 10%;
}

.titreSection{
  margin-bottom: 50px;
  font-size: 40px;
  color: var(--blue_dark);
}


h1{
  font-size: 50px;
}

p{
  font-size: 20px;
}



/* NOTIFICATION */
.notifGlobal {
  background-color: var(--error);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
}

.error {
  color: var(--default);
  background-color: var(--error);
  height:40px;
  text-align: center;
  padding: 20px;
}

.success{
  background-color: var(--green);
  color: var(--default);
  height:40px;
  text-align: center;
  padding: 20px;
}


/* NAVIGATION - ITEMS MENU */
/* ul { */
.ulNav{
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 80px;
  background-color: var(--default);
  position: fixed;
  height: 100%;
  overflow: auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid var(--medium);
}

li a {
  display: block;
  /* color: #000; */
  padding: 8px 8px 6px 8px;
  text-decoration: none;
  border-radius: 50%;
  background-color: var(--blue_dark);
  color: white;
}

li a.active {
  background-color: var(--text_default);
  color: white;
  justify-content: space-between;
  display: flex;
}

.navItemIconContainer{
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.navItemIcon {
  width: 30px;
  height: 30px;
}

.navItemIcon:hover {
  opacity: 0.8;
}

.avatar a{
  background-color: #ffffff;
}

.avatar:hover{
  background-color: #ffffff;
}

.avatar img{
  border-radius: 50%;
  width: 50px;
  height: 50px;
}



/* WEATHER */
.iconWeather{
  width: 30px;
}

.weatherText{
  font-size: 12px;
}

.weatherTextLink:hover{
  opacity: 0.8;
}

.weatherTextText{
  margin-top: 10px;
  font-size: 11px;
}




/* VISITOR - INTRO */
.nameDivVisitor{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logoVisitor {
  width: 50px;
  display: inline;
  border-radius: 50%;
}

.hiVisitor{
  margin-right: 20px;
}

.IntroDiv{
  text-align: left;
  padding: 6% 10% 4% 10%;
}

.introText{
  padding-top: 40px;
  line-height: 2;
  width: 90%;
}


.introTextGeneral{
  padding-bottom: 20px;
}


.divVisitorDetail {
  margin: 30px 0;
}

.logoCopyVisitor{
  width: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.divLinksVisitor{
  width: 300px;
  margin: 30px auto 0 auto;
}

.divLinksVisitorLinks{
  margin: 0 10px;

}




/* EDUCATION */
.educationGlobal {
position: relative;
}

.educationItems{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.educationItemsTop{
  display: flex;
  align-items: center;
}

.educationItemsBottom{
  display: flex;
  align-items: center;
}

.educationItem{
  /* width: 180px; */
  /* height: 200px; */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  position: relative;
}

.educationItemTop{
  margin: 0 60px 20px 0px;
  align-items: center;
}

.educationItemBottom{
  margin: 40px 0px 0 80px;
  align-items: center;
}

.educationItem img {
  opacity: 1;
  display: block;
  width: 180px;
  /* border-radius: 50%; */
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 5%;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 300px;
  cursor: pointer;
}

.educationItem:hover img {
  opacity: 0.3;
}

.educationItem:hover .middle {
  opacity: 1;
}

.textMiddle {
  background-color: var(--default);
  color: var(--standard_text);
  font-size: 20px;
  padding: 16px 32px;
  border-radius: 5%;
  /* border: 1px solid var(--standard_text) ; */
}

.moreIcon{
  width: 30px !important;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  opacity: 1 !important;
}





/* TIMELINE */
#timeline {
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,37,9,1) 10%, rgba(115,45,20,1) 60%, rgba(81,94,89,1) 84%, rgba(0,212,255,1) 100%);   */

  background: rgb(52,89,199);
  background: linear-gradient(90deg, rgba(52,89,199,1) 0%, rgba(63,135,189,1) 42%, rgba(199,233,253,1) 91%, rgba(234,247,246,1) 100%);

  height: 4px;
  width: 80%;
  opacity: 0.8;
  position: absolute;
  bottom: 40%;
  left: 10%;
}

.circle {
  background: transparent;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
}

.arrow {
  border: solid #3f87bd;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;

}

#endLeft {
  top: -8px;
  left: -17px;
  border: 4px solid  #3459c7;

}
#endRight {
  top: -3px;
  right: -17px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}




/* TECHNO */
.technoItems{
    display: grid;
    grid-template-columns: 200px 200px 200px;
    justify-content: center;
  }

.technoItem {
  height: 200px;
  width: 200px;
}




/* PROJECT */
.projectGlobal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projectItem{
  width: 400px;
  height:400px;
  margin: 0 10px;
  position: relative;
}

.tagProject{
position: absolute;
width: 60px;
background-color: #ffc874;
right: 10px;
top: 10px;
z-index: 3;
font-size: 12px;
border-radius: 5px;
padding: 5px 10px;
}

.projectItemTop {
  width: auto;
  height: 200px;
  position: relative;
}

.projectItemTop img {
  width: 300px;
  position: relative;
  top:30%;
}

.projectItemTop:hover img {
  opacity: 0.3;
}

.projectItemTop:hover .middle {
  opacity: 1;
}

.projectItemBottom{
  margin-top: 30px;
}

.tech_icons {
  padding: 1% ;
  margin-top: 15px;
}

.tech_icons img {
  width: 10%;
  padding: 0 2%;
}

.github_portf:hover  {
  opacity: 0.8;
}



/* MODAL */
.modal {
  display: block;
  position: fixed; 
  z-index: 4; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0,0,0,0.5);
}

.modalContent {
  background-color: rgb(255, 255, 255);
  margin: 15% auto; 
  padding: 40px 10px;
  border: 1px solid #888;
  width: 40%; 
  height: auto;
  position: relative;
  border-radius: 2%;
}

.modalTitle{
  margin-bottom: 20px;
}
.modalText{
  line-height: 1.8;
}
.modalTextText{
  font-size: 16px;
}
.modalLink{
  padding: 30px 0 10px 0;
  font-size: 12px;
}
.textinfo{
  font-size: 12px;
  font-style: italic;
  padding: 5px 0 0px 0;
}

.closeIcon{
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 4%;
  right: 4%;
}




/* CONTACT */
.contactContainerGlobal{
  padding: 4% 2%;
  background-color: var(--light_blue);
  margin: 4% 0 0 0;
}

.contactContainer{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  color: var(--default);
}

.textContact {
  width: 33.33333333%;
  text-align: left;
  padding-left: 100px;
  color: var(--standard_text);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.formContact{
  width: 60%;
}

input, textarea {
  width: 90%;
  margin: 10px 0 10px 10px;
  padding:10px 0 10px 10px;
  border: 1px solid var(--blue_dark);
  border-radius: 5px;
  font-family:inherit;
  font-size:16px;
}


.submit_button {
  width: 200px;
  margin:0 auto;
  padding:10px 0;
  border-radius: 5%;
  background-color: var(--blue_dark);
  color: #fff;
  margin-left: 10px;
  cursor:pointer;
  border: none;
  font-size: 16px;
}

.submit_button:hover{
  background-color: var(--standard_text);
}

.icon_contact {
  margin: 0 10px 20px 10px;
  width: 40px;
}

.icon_contact:hover {
  opacity: 0.8;
}




@media (max-width: 1600px) {
  .formContact{
    width: 50%;
  }
}



@media (max-width: 1200px) {

  .modalContent {
    width: 70%;
  }

  /* ul { */
    .ulNav{
    width: 100%;
    height: auto;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    border-bottom: 1px solid var(--medium);
    border-right: none;
    margin: 0;
    padding: 0;
    background-color: var(--default);
    position: fixed;
    overflow: auto;
    z-index: 2;
  }

  ul{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    border-right: none;
  }



  li a {
    padding: 8px 8px 4px 8px;
  }

  
  .menu{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navItemIconContainer{
    margin: 10px 10px;

  }

  .IntroDiv{
    padding: 100px 10% 6% 10%;
  }

  .error, .success {
    height:100px;
  }

  #timeline {
    height: 4px;
    width: 60%;
    bottom: 50%;
    left: 5%;
    rotate: 90deg;
  }

  .educationItems{
    flex-direction: column;
    flex-wrap: wrap;
  }

  .educationItemsTop{
    display: flex;
    flex-direction: column; 
    margin: 0 auto;
  }

  .educationItemTop{
    margin: 0 0 10px 0;
  }

  .educationItemsBottom{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .educationItemBottom{
    margin: 0 0 10px 0;
  }

}



@media (max-width: 950px) {

   #timeline {
    width: 80%;
    left: -10%;
  }
}


@media (max-width: 750px) {

  .container {
    padding:20% 10%;
  }

  .contactContainerGlobal{
    padding: 10% 2%;
  }
  

  
  .navItemIcon {
    width: 20px;
    height: 20px;
  }

  .navItemIconContainer{
    display: block;
    margin: 5px;
  }

  .weatherText{
    font-size: 10px;
  }

  .weatherTextText{
    margin-top: 5px;
  }


  #timeline {
    width: 100%;
    left: -30%;
  }


  .technoItems{
    grid-template-columns: 120px 120px 120px;
  }

  .technoItem{
    width: 120px;
    height: 120px;
  }

  .projectItem{
    height: 310px;
    width: 300px;
    margin:0;
  }
  
  .projectItemTop{
    height: 150px;
  }

  .projectItemTop img {
    width: 250px;
    top: 25%;
    }

  .contactContainer{
    flex-direction: column;
    align-content: center;
  }

  .textContact {
    width: 80%;
    text-align: center;
    padding-left: 0;
  }

  .formContact{
    width: 70%;
    margin: 0 auto;
  }


  .textContactTitre{
    margin-bottom: 20px;
  }
    .textContactIcons{
    margin-top: 20px;
  }


}


@media (max-width: 600px) {

  .nameDivVisitor{
    display: block;
  }

  .hiVisitor{
    margin-bottom: 10px;
  }


}

@media (max-width: 500px) {

  #timeline {
    width: 130%;
    left: -45%;
  }

  .hiVisitor{
    margin-bottom: 10px;
  }

}

@media (max-width: 400px) {

  #timeline {
    width: 160%;
    left: -65%;
  }

}

/* images
1698 × 856
https://viewportsizer.com/what-is-my-screen-size/
https://whatismyviewport.com/
MAC: 1676 × 861
 */
